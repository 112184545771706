import React, { Fragment } from 'react';
import Hero2 from '../../components/hero2/hero2';
import RsvpSection from '../../components/RsvpSection/RsvpSection';
import EventSection from '../../components/EventSection/EventSection';
import Contactpage from "../../components/Contactpage/Contactpage";
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'



const HomePage = () => {

    return (
        <Fragment>
            <Hero2 />
            <EventSection/>
            <RsvpSection/>
            <Contactpage/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;