import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/esztramos.jpg'
import sImg2 from '../../images/event/templom.jpg'
import LocationMap from './Modal'


const Events = [
    {
        Simg: sImg1,
        title: 'Vendégvárás',
        li1: '2025. május 31, Szombat 14:00 - 14:00',
        li2: '3764 Bódvarákó',
        li3: 'Szabadság út 46.',
        li4: '+36 30 791 26 28',
        animation: '1200',
        mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2643.1331641787606!2d20.7366095!3d48.5115119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473f0c75bbcb0547%3A0x515715fb00c8e6d5!2zQsOzZHZhcsOha8OzLCBTemFiYWRzw6FnIMO6dCA0NiwgMzc2NA!5e0!3m2!1shu!2shu!4v1724489614069!5m2!1shu!2shu',
    },
    {
        Simg: sImg2,
        title: 'Templomi szertartás',
        li1: '2025. május 31, Szombat 14:00 - 14:00',
        li2: '3761 Szin',
        li3: 'Szabadság út 33.',
        li4: '+36 30 791 26 28',
        animation: '1400',
        mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d660.9632360748576!2d20.662274163918536!3d48.497711798085184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473f0b02cba64381%3A0xb8c3bb01e17f8e79!2zU3ppbiwgU3phYmFkc8OhZyDDunQgMzMsIDM3NjE!5e0!3m2!1shu!2shu!4v1724489907663!5m2!1shu!2shu',
    },
    {
        Simg: sImg1,
        title: 'Polgári / Vacsora',
        li1: '2025. május 31, Szombat 14:00 - 14:00',
        li2: '3764 Bódvarákó',
        li3: 'Szabadság út 46.',
        li4: '+36 30 791 26 28',
        animation:'1600',
        mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2643.1331641787606!2d20.7366095!3d48.5115119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473f0c75bbcb0547%3A0x515715fb00c8e6d5!2zQsOzZHZhcsOha8OzLCBTemFiYWRzw6FnIMO6dCA0NiwgMzc2NA!5e0!3m2!1shu!2shu!4v1724489614069!5m2!1shu!2shu',
    },

]

const EventSection = (props) => {
    return (
        <section className="wpo-event-section section-padding" id="event">
            <div className="container">
                <SectionTitle subTitle={'A mi nagy napunk'} MainTitle={'Hol és Mikor'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt="" />
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li>{event.li2}</li>
                                                <li>{event.li3}</li>
                                                <li>{event.li4}</li>
                                                <li><LocationMap url={event.mapUrl}/></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;